<template>
  <div id='community'>
    <div class='page'>
      <div class='left-list'>
        <div class='left-title'>社群列表</div>
        <a-spin :spinning='listLoading' class='list-box'>
          <div class='list' v-if="list.length">
            <div class='item' @click='changeCommunity(item)' :class='{active: currentId=== item.communityGroupId}' v-for='item in list' :key='item.communityGroupId'>
              <base-img :src='item.communityGroupLogo' class="item-logo"></base-img>
              <div class='item-text'>
                <a-popover placement="right">
                  <template slot="content">
                    <p style='max-width:500px'>{{item.communityGroupDesc}}</p>
                  </template>
                  <template slot="title">
                    <span>{{item.communityGroupName}}</span>
                  </template>
                  <div class='item-name el'>{{item.communityGroupName}}</div>
                </a-popover>
                <div class='item-desc el'>社群微信号：{{item.wxNum}}</div>
              </div>
              <div @click='viewQrcode($event,item.communityGroupQrcode)'>
                <icon-svg class='item-icon' icon-class="qrcode-icon" />
              </div>
            </div>
          </div>
          <a-empty description='暂无数据' style='padding-top:32px;' v-else />
        </a-spin>
        <div class='opt-box'>
          <a-button icon='plus' type='link' @click='add'>添加</a-button>
          <a-button icon='edit' type='link' v-if="currentId" @click='edit'>编辑</a-button>
          <a-button icon='delete' type='link' v-if="currentId" @click='del'>删除</a-button>
        </div>

      </div>
      <div class='right-box'>
        <div class='right-head flex-a-c flex-j-b'>
          <div style='font-size:16px;font-weight:900'>
            <template v-if="currentData.communityGroupName">【{{currentData.communityGroupName}}】所</template>关联的商品
          </div>
          <div class=' flex-a-c flex-j-b' style='flex:1;margin-left:64px;' v-if="currentId">
            <a-button icon="plus" type="primary" @click='showSelectProductModal' :disabled='currentId===""'>添加商品</a-button>
            <div class='flex-a-c'>

              <a-checkbox class='name' :disabled='tableData.length===0' :indeterminate='indeterminate' @change="onSelectAll" :checked="checkAll">
                全选
              </a-checkbox>
              <a-button type='primary' style='margin: 0 16px' @click="changeGroup" :disabled='selectIds.length===0'>切换社群</a-button>
              <a-button type='primary' :disabled='selectIds.length===0' @click='deleteSelectedProducts'>移除</a-button>
            </div>
          </div>
        </div>
        <div class='product-box'>
          <a-spin :spinning='tableLoading' style='height:100%'>
            <div class='product-list' v-if="tableData.length">
              <div class='product-item' v-for='item in tableData' :key='item.productId'>
                <base-img :src='item.productMainPic' class='product-img'></base-img>
                <!-- <div class='product-name el'></div> -->
                <a-checkbox :checked="selectIds.findIndex(x=>x === item.productId) !== -1" class='product-name el' :value="item.productId" @change='onSelectProduct'>{{item.productName}}</a-checkbox>
              </div>
            </div>
            <div v-else class='flex-a-c' style='width:100%;height:100%;justify-content: center;'>
              <a-empty :description='currentId?"暂无数据":"请先选择社群"' />
            </div>
          </a-spin>
        </div>
        <div class='pagination-box'>
          <a-pagination :show-total="total => `共 ${pagination.total} 条`" show-less-items show-quick-jumper size="small" v-model="pagination.current" :pageSize='pagination.pageSize' :total="pagination.total" @change='changePage' />
        </div>
      </div>
    </div>
    <a-modal :getContainer='getContainer' v-model="visible" width="600px" :title="isAdd ? '添加社群':'编辑社群'" @ok='submit' :confirmLoading='submitLoading'>
      <a-form-model ref="formRef" :model="form" @submit.native.prevent :label-col="{span:4}" :wrapper-col="{span:20}" :rules="rules">
        <a-form-model-item label='社群名称' prop="communityGroupName">
          <a-input v-model="form.communityGroupName" type="text" placeholder="请输入社群名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label='社群描述' prop="communityGroupDesc">
          <a-textarea :rows="4" v-model="form.communityGroupDesc" type="text" placeholder="请输入社群描述"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label='社群LOGO' prop="communityGroupLogo">
          <picture-card-upload :fileList="form.communityGroupLogo?[form.communityGroupLogo]:[]" @update:fileList='res=>form.communityGroupLogo=res.length?res[0]:""' :max="1" />
        </a-form-model-item>
        <a-form-model-item label='社群微信号' prop="wxNum">
          <a-input v-model="form.wxNum" type="text" placeholder="请输入社群微信号"></a-input>
        </a-form-model-item>
        <a-form-model-item label='社群二维码' prop="communityGroupQrcode">
          <picture-card-upload :fileList="form.communityGroupQrcode?[form.communityGroupQrcode]:[]" @update:fileList='res=>form.communityGroupQrcode=res.length?res[0]:""' :max="1" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="previewVisible" :footer="null">
      <base-img class='view-img' :src="previewImage"></base-img>
    </a-modal>

    <a-modal  :getContainer='getContainer' class='product-modal' width='600px' v-model="selectGoodsModal" title="选择商品" :maskClosable="false" @cancel="selectGoodsModal = false;productName = ''" @ok="selectedDone" :confirmLoading='selectedDoneLoading'>
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="flex:1;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="getTableList">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table :pagination="modalPagination" :columns="columns" :dataSource="allGoods" :rowKey="record => record.productId" :loading="selectGoodsLoading" :row-selection="{ selectedRowKeys: goodIds, onChange: onSelectChange}" @change="handleTableChange" class='product-table'>
        <template slot="name" slot-scope="text,record">
          <div class="table-goods-info">
            <base-img class="goods-img" :src="record.mainPicPath" width="60" height="60" />
            <a-button type="link" class="goods-name" @click="goGoodsDetail(record.productId)">{{record.name}}</a-button>
          </div>
        </template>
      </a-table>
    </a-modal>

    <a-modal  :getContainer='getContainer' :confirmLoading='selectGroupLoading' v-model="showSelectGroup" :width='380' title="切换社群" @ok="handleChangeGroup">
      <div class='select-group-list' v-if='list.filter(x=>x.communityGroupId !== currentId).length'>
        <div v-for='item in list.filter(x=>x.communityGroupId !== currentId)' :key='item.id' @click='selectGroupId = item.communityGroupId' class='select-group-item flex-a-c flex-j-b' :class='{active: selectGroupId === item.communityGroupId}'>
          <div>{{item.communityGroupName}}</div>
          <a-icon class='active' type="check" v-if='selectGroupId === item.communityGroupId' />
        </div>
      </div>
      <a-empty v-else description="暂无可切换社区"></a-empty>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from '@vue/composition-api'
import { content } from '@/api'
import basePick from 'lodash/_basePick'
export default {
  name: 'communotyList',
  setup (props, { root }) {
    const state = reactive({
      list: [],
      currentId: '',
      currentData: {},
      form: {
        communityGroupName: '',
        communityGroupDesc: '',
        wxNum: '',
        communityGroupLogo: '',
        communityGroupQrcode: '',
      },
      formRef: null,
      selectGoodsModal: false,
      previewVisible: false,
      previewImage: '',
      visible: false,
      submitLoading: false,
      isAdd: true,
      listLoading: false,
      tableLoading: false,
      selectedDoneLoading: false,
      indeterminate: false,
      selectGoodsLoading: false,
      selectGroupLoading: false,
      showSelectGroup: false,
      checkAll: false,
      tableData: [],
      goodIds: [],
      selectIds: [],
      selectGroupId: '',
      productName: '',
      allGoods: [],
      pagination: {
        current: 1,
        pageSize: 28,
        total: 0,
      },
      modalPagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small',
      },
      rules: {
        communityGroupName: [
          { required: true, message: '请输入社群名称', trigger: 'change' },
          { max: 16, message: '不得超过16个字' },
        ],
        communityGroupDesc: [
          { required: true, message: '请输入社群描述', trigger: 'change' },
          { max: 200, message: '不得超过200个字' },
        ],
        communityGroupLogo: [{ required: true, message: '请上传', trigger: 'change' }],
        communityGroupQrcode: [{ required: true, message: '请上传', trigger: 'change' }],
        wxNum: [
          { required: true, message: '请输入社群微信号', trigger: 'change' },
          { max: 20, message: '不得超过20个字' },
        ],
      },
      columns: [
        {
          title: '商品',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
        },
      ],
    })
    onMounted(() => {
      getList()
    })
    watch(
      () => state.selectIds,
      (v) => {
        let num = 0 // 选中当前页的数量
        if (v.length) {
          v.forEach((item) => {
            state.tableData.forEach((val) => {
              if (item === val.productId) {
                num++
              }
            })
          })
        }

        // const num = v.length
        if (num === 0) {
          state.indeterminate = false
          state.checkAll = false
        } else if (num === state.tableData.length) {
          state.indeterminate = false
          state.checkAll = true
        } else {
          state.indeterminate = true
          state.checkAll = false
        }
      }
    )
    watch(
      () => state.tableData,
      (v) => {
        let num = 0 // 选中当前页的数量
        if (state.selectIds.length) {
          state.selectIds.forEach((item) => {
            state.tableData.forEach((val) => {
              if (item === val.productId) {
                num++
              }
            })
          })
        }

        if (num === 0) {
          state.indeterminate = false
          state.checkAll = false
        } else if (num === v.length) {
          state.indeterminate = false
          state.checkAll = true
        } else {
          state.indeterminate = true
          state.checkAll = false
        }
      }
    )
    async function deleteSelectedProducts () {
      root.$confirm({
        title: '提示',
        content: `确定要将选中的${state.selectIds.length}个商品与【${state.currentData.communityGroupName}】解除关联？`,
        onOk: async () => {
          const { code, msg } = await content.removeProduct({
            productIds: state.selectIds.join(','),
          })
          if (code === '00000') {
            root.$message.success('解除关联成功！')
            state.selectIds = []
            state.pagination.current = 1
            getProductsData()
          } else {
            root.$message.error(msg || '解除关联失败！')
          }
        },
      })
    }
    function onSelectAll (e) {
      state.checkAll = e.target.checked
      if (state.checkAll) {
        // 去重 合并
        state.selectIds = Array.from(
          new Set(state.tableData.map((x) => x.productId).concat(state.selectIds))
        )
        console.log(state.selectIds)
      } else {
        state.selectIds = state.selectIds.filter(
          (x) => state.tableData.map((z) => z.productId).indexOf(x) === -1
        )
      }
    }
    function getContainer () {
      return document.getElementById('community')
    }
    async function submit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          state.submitLoading = true
          const { data, msg, code } = await content.communityGroupSave(state.form)
          state.submitLoading = false
          if (code === '00000') {
            root.$message.success(state.isAdd ? '添加成功' : '修改成功')
            state.visible = false
            getList()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    function changePage (current) {
      state.pagination.current = current
      getProductsData()
    }
    function showSelectProductModal () {
      state.selectGoodsModal = true
      getTableList()
    }
    async function selectedDone () {
      state.selectedDoneLoading = true
      const { code, msg } = await content.bindProduct({
        communityGroupId: state.currentId,
        productIds: state.goodIds.join(','),
      })
      state.selectedDoneLoading = false
      if (code === '00000') {
        root.$message.success('关联成功')
        state.selectGoodsModal = false
        state.modalPagination.current = 1
        getProductsData()
      } else {
        root.$message.error(msg || '关联失败')
      }
    }
    function goGoodsDetail (id) {
      root.$router.push(`/goods/goods_detail/${id}`)
    }
    async function getTableList () {
      state.selectGoodsLoading = true
      const { data, msg, code, page } = await content.getChooseProducts({
        current: state.modalPagination.current,
        size: state.modalPagination.pageSize,
        communityGroupId: state.currentId,
        productName: state.productName,
      })
      state.selectGoodsLoading = false
      if (code === '00000') {
        state.allGoods = data
        state.modalPagination.total = page.total
      } else {
        root.$message.error(msg || '获取商品列表失败')
      }
    }
    function resetSearch () {
      state.productName = ''
      getTableList()
    }
    function onSelectChange (selectedRowKeys) {
      state.goodIds = selectedRowKeys.reverse()
    }
    function handleTableChange ({ current }) {
      state.modalPagination.current = current
      getTableList()
    }
    async function getList () {
      state.listLoading = true
      const { data, msg, code } = await content.getCommunityGroupList()
      state.listLoading = false
      if (code === '00000') {
        state.list = data
        if (data && data.length) {
          if (!state.currentId) {
            state.currentId = data[0].communityGroupId
            state.currentData = data[0]
            getProductsData()
          }
        }
      } else {
        root.$message.error(msg || '获取社群列表数据失败')
      }
    }
    async function getProductsData () {
      state.tableLoading = true
      const { data, msg, code, page } = await content.getCommunityGroupProducts({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        communityGroupId: state.currentId,
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取社群商品失败')
      }
    }
    async function add () {
      state.isAdd = true
      state.visible = true
      await root.$nextTick()
      state.formRef.resetFields()
      state.form = {
        communityGroupName: '',
        communityGroupDesc: '',
        wxNum: '',
        communityGroupLogo: '',
        communityGroupQrcode: '',
      }
    }
    async function edit () {
      state.isAdd = false
      state.visible = true
      state.form = deepClone(state.currentData)
      await root.$nextTick()
      state.formRef.clearValidate()
    }
    function del () {
      root.$confirm({
        title: '删除提升',
        content: `确定要删除【${state.currentData.communityGroupName}】？删除后该社群所关联的商品将被重置关联关系`,
        onOk: async () => {
          const { code, msg } = await content.deleteCommunityGroup({
            communityGroupId: state.currentId,
          })
          if (code === '00000') {
            root.$message.success('删除成功')
            getList()
          } else {
            root.$message.error('删除失败')
          }
        },
      })
    }
    function viewQrcode (e, url) {
      e.stopPropagation()
      state.previewVisible = true
      state.previewImage = url
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function changeCommunity (item) {
      state.currentData = item
      state.currentId = item.communityGroupId
      getProductsData()
    }
    function onSelectProduct (e) {
      const checked = e.target.checked
      const value = e.target.value
      if (checked) {
        if (state.selectIds.indexOf(value) === -1) {
          state.selectIds.push(value)
        }
      } else {
        const index = state.selectIds.findIndex((x) => x === value)
        state.selectIds.splice(index, 1)
      }
    }

    async function handleChangeGroup () {
      state.selectGroupLoading = true
      const { msg, code } = await content.changeCommunityGroup({
        productIds: state.selectIds.join(','),
        newCommunityGroupId: state.selectGroupId
      })
      state.selectGroupLoading = false
      if (code === '00000') {
        state.showSelectGroup = false
        root.$message.success('商品切换社群成功！')
        state.pagination.current = 1
        state.selectGroupId = ''
        state.selectIds = []
        getProductsData()
      } else {
        root.$message.error(msg || '商品切换社群失败！')
      }
    }
    function changeGroup () {
      state.showSelectGroup = true
    }
    return {
      ...toRefs(state),
      submit,
      add,
      del,
      edit,
      changePage,
      deleteSelectedProducts,
      onSelectAll,
      viewQrcode,
      changeCommunity,
      selectedDone,
      getTableList,
      handleTableChange,
      resetSearch,
      onSelectChange,
      showSelectProductModal,
      onSelectProduct,
      handleChangeGroup,
      changeGroup,
      goGoodsDetail,
      getContainer
    }
  },
}
</script>

<style lang="less" scoped>
.page {
  height: calc(100vh - 136px);
  display: flex;
  .left-list {
    margin-right: 16px;
    width: 300px;
    background: #fff;
    box-shadow: 0 8px 12px #ddd;
    height: 100%;
    box-sizing: border-box;
    padding-top: 8px;
    flex-shrink: 0;
    .left-title {
      line-height: 48px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      padding: 0 16px;
    }
    .list-box {
      box-sizing: border-box;
      height: calc(100% - 96px);
      overflow: auto;
      .list {
        height: 100%;
        width: 100%;
        overflow: auto;
        // background: #f1f1f1;
        .item {
          padding: 12px 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          cursor: pointer;
          :hover .item-name {
            color: #1890ff !important;
          }
          &.active .item-name {
            color: #1890ff !important;
          }
          &.active {
            background: #e6f7ff;
          }
          .item-logo {
            width: 32px;
            height: 32px;
          }
          .item-icon {
            font-size: 28px;
          }
          .item-text {
            flex: 1;
            width: calc(100% - 84px);
            margin: 0 12px;
            .item-name {
              font-size: 14px;
              font-weight: blod;
            }
            .item-desc {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
    .opt-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid #ddd;
      height: 48px;
      button {
        display: block;
      }
    }
  }
  .right-box {
    flex: 1;
    background: #eee;
    box-shadow: 0 8px 12px #ddd;
    height: 100%;
    min-width: 800px;
    box-sizing: border-box;

    .product-box {
      height: calc(100% - 112px);
      padding: 8px;
      overflow: auto;
      .product-list {
        display: flex;
        flex-wrap: wrap;
        .product-item {
          width: calc((100% - 48px) / 7);
          margin-bottom: 8px;
          margin-right: 8px;
          background: #fff;
          border-radius: 6px;
          box-shadow: 0 8px 12px #ddd;
          overflow: hidden;
          &:nth-of-type(7n) {
            margin-right: 0;
          }
          .product-img {
            width: 100%;
            display: block;
            margin: 0 auto;
            ::v-deep img {
              height: 100px;
              object-fit: cover;
            }
          }
          .product-name {
            width: 100%;
            margin: 0 auto;
            line-height: 16px;
            padding: 4px;
            display: block;
          }
        }
      }
    }
    .right-head {
      height: 64px;
      background: #fff;
      padding: 0 16px;
    }
    .pagination-box {
      background: #fff;
      padding: 8px 16px;
      text-align: right;
      height: 48px;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
::v-deep .ant-form-item {
  margin-bottom: 4px;
}
::v-deep .ant-spin-container {
  height: 100%;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.flex-j-b {
  display: flex;
  justify-content: space-between;
}
.flex-j-e {
  display: flex;
  justify-content: flex-end;
}
::v-deep .item-logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
::v-deep .item-qrcode img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
::v-deep .view-img img {
  width: 100%;
  height: 100%;
  margin-top: 24px;
}
.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
.product-modal ::v-deep .ant-modal-body {
  padding: 0 24px 12px;
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.product-table ::v-deep td {
  padding: 4px 12px;
}
.select-group-list {
  max-height: 400px;
  overflow: auto;
}
.select-group-item {
  height: 44px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  .active {
    color: @main-color;
  }
  &.active {
    color: @main-color;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: @main-color;
  }
}
</style>
